import React from 'react';
import ImageLink from '../../../../core/Components/ImageLink';
import {Text, Image, RichText} from '@sitecore-jss/sitecore-jss-react';

const Socials = ({fields}) => {
	return (
		<li className="nav-footer-social nav-footer-item link-icons-disabled">
			<ul>
				{fields.socials.map(({fields, id}) => (
					<li className="socialsContent">
						<Image field={fields.image} alt={fields.title.value} />
						<div className="intro">
							<Text field={fields.title} tag="p" />
							<RichText field={fields.intro} tag="p" />
						</div>
					</li>
				))}
			</ul>
		</li>
	);
};

export default Socials;
