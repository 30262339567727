import React from 'react';
import {Link, Text} from '@sitecore-jss/sitecore-jss-react';

const Nav = ({title, links, columnClass}) => {
	return (
		<li className={`nav-footer-item ${columnClass}`}>
			{title && <Text field={title} data-amid={title.id} tag="h2" />}
			<ul>
				{links.map(({fields, id}) => (
					<li key={id}>
						<Link key={fields.link.id} field={fields.link} />
					</li>
				))}
			</ul>
		</li>
	);
};
export default Nav;
